<template>
  <BaseCard
    title="datos del rol"
    :loading="loading"
    @save-button-click="handleSaveButtonClick"
  >
    <RoleProfileForm
      ref="role-profile-form"
      v-model="role"
    />
  </BaseCard>
</template>

<script>
import ApiRestService from '@/api/base-api'
import BaseCard from '@/components/ui/card/BaseCard.vue'
import RoleProfileForm from '@/components/role/form/RoleProfileForm.vue'

export default {
  name: 'Create',
  components: { RoleProfileForm, BaseCard },
  data() {
    return {
      loading: false,
      role: null,
    }
  },
  computed: {
    roleProfileForm() {
      return this.$refs['role-profile-form']
    },
  },
  methods: {
    async handleSaveButtonClick() {
      const valid = await this.roleProfileForm.validateForm()
      if (!valid) {
        return
      }

      this.loading = true
      try {
        delete this.role.capabilities
        const response = await ApiRestService.create(this.role, this.$data.$constants.RESOURCES.RESOURCE_ROLES)
        await this.$router.replace({ name: 'viewRole', params: { id: response.data.id } })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>

</style>
